import { Box, Image, SimpleGrid, Text } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Layout } from "../components/layout"
import { Title } from "../components/title"
import { SEO } from "../components/seo"

const Videos: FunctionComponent<{ data: any }> = ({ data }) => {
  const videos = data.allPrismicVideos.nodes

  return (
    <Layout>
      <Box
        position="fixed"
        h="100vh"
        w="100vw"
        bg="#fff"
        zIndex={-1}
        opacity={0.35}
      />
      <Container>
        <Title text="VIDEÓK" />
        <SEO title="VIDEÓK" />

        <SimpleGrid columns={[1, 2, 3]} spacing={10}>
          {videos?.map((video: any, index: number) => {
            const { youtube } = video.data
            return (
              <Box w="100%" cursor="pointer" as="a" href={youtube.embed_url}>
                <Image src={youtube.thumbnail_url} />
                <Text mt={2} textAlign="center" fontWeight="bold">{youtube.title}</Text>
              </Box>
            )
          })}
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Videos {
    allPrismicVideos {
      nodes {
        data {
          youtube {
            title
            embed_url
            thumbnail_url
          }
        }
      }
    }
  }
`

export default Videos
